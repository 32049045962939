import Image from "next/image";
import { useEffect, useState } from "react";

import defaultImage from "../public/fallback.jpg";

const CustomImage = ({ ...props }) => {
  const [imageData, setImageData] = useState({
    src: props?.src,
    alt: props?.alt,
  });

  useEffect(() => {
    setImageData({ src: props.src, alt: props.alt });
  }, [props.alt, props.src]);

  const handleError = () => {
    setImageData({ src: defaultImage?.src, alt: "BettingsNews Default Image" });
  };

  return (
    <Image
      {...props}
      alt={imageData.alt}
      src={imageData.src}
      onError={handleError}
    />
  );
};

export default CustomImage;
