import Card from "./components/Card";

const CardGrid = ({ items }) => (
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
    {items?.map((item, index) => (
      <Card key={`${item?.id}-${index}`} item={item} />
    ))}
  </div>
);

export default CardGrid;
