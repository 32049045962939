import { useEffect, useState } from "react";

import { getStartTimeDate } from "@/utilities/dates";

export const useLocalTime = ({ startTime, format }) => {
  const [localTime, setLocalTime] = useState("");

  useEffect(() => {
    setLocalTime(
      getStartTimeDate({
        startTime,
        format,
      })
    );
  }, [startTime, format]);

  return localTime;
};
