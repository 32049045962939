import { useEffect, useState } from "react";

import { list as svgList } from "./list";

const SvgIcon = ({ iconName, ...props }) => {
  const [SvgComponent, setSvgComponent] = useState(null);

  useEffect(() => {
    if (svgList.includes(iconName)) {
      import(`../icon/list/${iconName}.js`).then((DynamicSvg) => {
        if (DynamicSvg) {
          setSvgComponent(() => DynamicSvg.default);
        }
      });
    }
  }, [iconName]);

  if (!SvgComponent) {
    return null;
  }

  return (
    <SvgComponent
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill={props.svgfill ? props.svgfill : "none"}
      style={{
        fill: props.color ?? "none",
        stroke: props.stroke ?? "none",
        width: props.width ?? "20px",
        height: props.height ?? "20px",
      }}
    />
  );
};

export default SvgIcon;
