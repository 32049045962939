import Link from "next/link";

import CustomImage from "@/components/CustomImage";
import Icon from "@/components/icon/Icon";
import { useLocalTime } from "@/hooks/useLocalTime";
import useScreenWidth from "@/hooks/useScreenWidth";
import { getArticleCardDate } from "@/utilities/dates";

const Card = ({ isHeroCard, item, firstRow }) => {
  const formattedDate = useLocalTime({
    startTime: item?.date,
  });

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 991;
  const prioritizedImage = isHeroCard || item?.isImagePrioriy;
  return (
    <div className="flex h-full min-h-[250px] w-full flex-col rounded-lg border border-card-border bg-off-white">
      <Link
        aria-label={item?.title}
        className="relative w-full pb-[50%]"
        href={item?.uri ?? ""}
      >
        <CustomImage
          className="w-full rounded-t-lg object-cover"
          alt={item?.featuredImage?.alt || item?.title}
          priority={prioritizedImage}
          sizes={
            isHeroCard
              ? "(min-width: 36em) 60vw, 80vw"
              : "(min-width: 36em) 20vw, 40vw"
          }
          src={item?.featuredImage?.sourceUrl}
          srcSet={item?.featuredImage?.srcSet}
          fill
          loading={prioritizedImage ? "eager" : "lazy"}
        />
        <div
          className={`absolute bottom-0 left-0 flex h-[2rem] w-[4rem] items-end justify-center rounded-tr-lg bg-off-white ${firstRow ? "!h-[1.6rem] !w-[3rem] lg:h-[2rem] lg:w-[4rem]" : ""}`}
        >
          <Icon
            className={`h-[20px] w-[25px] text-red ${firstRow ? "!h-[14px] !w-[19px] md:h-[20px] md:w-[25px]" : ""}`}
            height={20}
            color="#dc3545"
            name={`${item?.categories ? item?.categories[0]?.slug?.replace("-", " ") : item?.tags[0]?.slug?.replace("-", " ")}Category`}
            width={25}
          />
        </div>
      </Link>
      <div
        className={`flex flex-col items-start p-6 ${firstRow ? "!p-[12px] md:p-6" : ""}`}
      >
        <Link
          aria-label={`${item?.categories ? item?.categories[0]?.name : item?.tags[0]?.name} News`}
          className="mb-2 flex text-[.8125rem] tracking-wide text-card-link-blue hover:text-card-link-hover 
        hover:underline"
          href={
            item?.categories
              ? item?.categories[0]?.uri ?? "/"
              : item?.tags[0]?.uri ?? "/"
          }
        >
          {item?.categories ? item?.categories[0]?.name : item?.tags[0]?.name}
        </Link>
        <Link
          aria-label={item?.title}
          className={`font-heading font-semibold text-card-title lg:text-xl ${firstRow ? "md:text-[20px] md:leading-7" : ""} ${isMobile ? "ellipsis !text-[0.925rem] leading-5" : ""}`}
          href={item?.uri ?? ""}
        >
          {item?.title}
        </Link>
        {formattedDate && (
          <div
            className={`mt-3 flex flex-row text-[.8125rem] text-card-title ${firstRow ? "flex-col md:flex-row" : ""}`}
          >
            <Link
              aria-label={`${item?.author?.name} Articles`}
              className="hover:underline"
              href={item?.author?.uri ?? ""}
            >
              {item?.author && `${item?.author?.name}`}
            </Link>
            <p
              className={`pl-2 text-darker-grey ${firstRow ? "!pl-0 md:ml-2" : ""}`}
            >
              {getArticleCardDate(formattedDate)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
