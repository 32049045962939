import { useEffect, useState } from "react";

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Set the initial screen width
    setScreenWidth(window.innerWidth);

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth;
};

export default useScreenWidth;
